import React, { useState } from "react";
import { DeleteVendorFeatureAPI } from "src/services/api";
import { Modal, Box, Typography, Button, Backdrop } from "@mui/material";

function DeletePip({
  openDelModal,
  setOpenDelModal,
  commodityId,
  vendorId,
  setAlertStatus,
  setAlertMessage,
  setOpenAlert,
  setOpen,
  setIsDeleted,
  getadmin,
}) {
  if (!openDelModal) return null;

  const handleConfirmDelete = () => {
    deletePip();
    setOpenDelModal(false);
  };

  const deletePip = async () => {
    try {
      const response = await DeleteVendorFeatureAPI({
        VendorSuffix: vendorId,
        CommoditySuffix: commodityId,
      });

      if (response.Success === false) {
        setAlertStatus(false);
      } else {
        setAlertStatus(true);
        setIsDeleted(true);
      }
      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      setOpen(false);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
        getadmin();
      }, 1000);
    }
  };

  return (
    <Modal
      open={openDelModal}
      onClose={() => setOpenDelModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.85)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 1,
          outline: "none",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Pip Silme İşlemi
        </Typography>
        <Typography variant="body1" gutterBottom>
          Silmek istediğinize emin misiniz?
        </Typography>
        <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
            sx={{ color: "#202020" }}
          >
            Sil
          </Button>
          <Button
            onClick={() => setOpenDelModal(false)}
            color="primary"
            variant="outlined"
          >
            İptal
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeletePip;
