// component
import { Menu } from "@mui/material";
import { CurrencyExchange } from "@mui/icons-material";
import MenuIcon from "src/assets/Icons/navbar/Menu";
import AccountsIcon from "src/assets/Icons/navbar/AccountsIcon";
import TransfersIcon from "src/assets/Icons/navbar/TransfersIcon";
import AdminListing from "src/assets/Icons/navbar/AdminListing";
import RolesIcon from "src/assets/Icons/navbar/RolesIcon";
import PricesIcon from "src/assets/Icons/navbar/PricesIcon";
import VendorIcon from "src/assets/Icons/navbar/VendorIcon";
import IslmlerIcon from "src/assets/Icons/navbar/IslmlerIcon";
import Home from "src/assets/Icons/Header/Home";
import TransactionsIcon from "src/assets/Icons/Header/TransactionsIcon";
import TransferIcon from "src/assets/Icons/Header/TransfersIcon";
import UsersIcon from "src/assets/Icons/Header/UsersIcon";
import EventsIcon from "src/assets/Icons/Header/EventsIcon";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Anasayfa",
    path: "/dashboard/Menu",
    icon: <MenuIcon />,
  },
  {
    title: "Fiyatlar",
    path: "/dashboard/Fiyatlar",
    icon: <PricesIcon />,
  },
  {
    title: "Fiyat Değişimleri",
    path: "/dashboard/vendorFeature",
    icon: <CurrencyExchangeIcon />,
  },

  {
    title: "Vendor İşlemleri",
    path: "/dashboard/vendorIslemleri",
    icon: <VendorIcon />,
  },

  {
    title: "Vendor Yöneticileri",
    path: "/dashboard/vendorYoneticileri",
    icon: <UsersIcon />,
  },
  {
    title: "Hesaplar",
    path: "/dashboard/hesaplar",
    icon: <AccountsIcon />,
  },
  {
    title: "İşlemler",
    path: "/dashboard/islemler",
    icon: <IslmlerIcon />,
  },
  {
    title: "İşlem Değişimleri",
    path: "/dashboard/islemDegisimleri",
    icon: <PublishedWithChangesOutlinedIcon />,
  },

  {
    title: "Admin İşlemleri",
    path: "/dashboard/adminListeleme",
    icon: <AdminListing />,
  },
  {
    title: "Rol İşlemleri",
    path: "/dashboard/rolIslemleri",
    icon: <RolesIcon />,
  },
  // {
  //   title: "Transferler",
  //   path: "/dashboard/transferler",
  //   icon: <TransfersIcon />,
  // },

  // {
  //   title: "App Panel",
  //   path: "/dashboard/appPanelAnasayfa",
  //   icon: <MenuIcon />,
  //   subMenu: [
  //     {
  //       title: "Anasayfa",
  //       path: "/dashboard/appPanelAnasayfa",
  //       icon: <MenuIcon />,
  //     },
  //     {
  //       title: "Kullanıcılar",
  //       path: "/dashboard/appPanelKullanicilar",
  //       icon: <UsersIcon />,
  //     },
  //     {
  //       title: "İşlemler",
  //       path: "/dashboard/appPanelIslemler",
  //       icon: <TransactionsIcon />,
  //     },
  //     {
  //       title: "Transferler",
  //       path: "/dashboard/appPanelTransferler",
  //       icon: <TransferIcon />,
  //     },
  //     {
  //       title: "Etkinlikler",
  //       path: "/dashboard/appPanelEtkinlikler",
  //       icon: <EventsIcon />,
  //     },
  //   ],
  // },
];

export default navConfig;
