import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { DeleteVendorFeatureAPI, GetVendorFeaturesAPI } from "src/services/api";
import { useTheme } from "@emotion/react";
import UpdateVendorFeature from "./UpdateVendorFeature";
import Popover from "@mui/material/Popover";
import DeletePip from "./DeletePip";
import { formatDate } from "src/utils/helpers/helper";

const GetVendorFeatureList = () => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [updateModal, setUpdateModal] = React.useState(false);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [commodityId, setCommodityId] = useState("");
  const [buyPipe, setBuyPipe] = useState("");
  const [sellPipe, setSellPipe] = useState("");
  const [openDelModal, setOpenDelModal] = useState(false);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "VendorId",
        header: "Vendor Id",
        size: 30,
      },
      {
        accessorKey: "CommodityId",
        header: "Commodity Suffix",
        size: 120,
      },
      {
        accessorKey: "BuyPipe",
        header: "Buy Pipe Miktarı",
        size: 120,
      },
      {
        accessorKey: "SellPipe",
        header: "Sell Pipe Miktarı",
        size: 120,
      },

      {
        accessorKey: "CreatedDate",
        header: "Created Date",
        size: 120,
      },
      {
        accessorKey: "ModifiedDate",
        header: "Modified Date",
        size: 120,
      },
    ],
    []
  );
  useEffect(() => {
    getadmin();
  }, []);

  const getadmin = async () => {
    try {
      const response = await GetVendorFeaturesAPI();
      if (response.Success === false) {
        setAlertStatus(false);
        setData([]);
      } else {
        setAlertStatus(true);

        const formattedData = response.Data.map((item) => {
          return {
            ...item,
            CreatedDate: formatDate(item.CreatedDate), // `createdDate`'i formatlar ve günceller
            ModifiedDate: formatDate(item.ModifiedDate),
          };
        });
        setData(formattedData);
      }

      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      setOpen(false);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
        setIsDeleted(false);
      }, 1000);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyCellProps: {
      sx: { backgroundColor: theme.palette.table },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.table,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: theme.palette.table,
      },
    },
    muiTopToolbarProps: {
      sx: {
        marginTop: "36px",
        backgroundColor: theme.palette.table,
      },
    },
    enableRowActions: true,

    renderRowActions: ({ row }) => {
      return (
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Button
            sx={{ fontSize: "12px" }}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();
              setVendorId(row.original.VendorId);
              setCommodityId(row.original.CommodityId);
              setBuyPipe(row.original.BuyPipe);
              setSellPipe(row.original.SellPipe);

              setUpdateModal(true);
            }}
            color={"success"}
          >
            Güncelle
          </Button>
          <Button
            sx={{ fontSize: "12px" }}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();
              setVendorId(row.original.VendorId);
              setCommodityId(row.original.CommodityId);
              setOpenDelModal(true);
            }}
            color={"error"}
          >
            Sil
          </Button>
        </Box>
      );
    },
  });

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      {data !== null && <MaterialReactTable table={table} />}
      {updateModal && (
        <UpdateVendorFeature
          open={updateModal}
          setOpen={setUpdateModal}
          getadmin={getadmin}
          setBuyPipe={setBuyPipe}
          buyPipe={buyPipe}
          setSellPipe={setSellPipe}
          sellPipe={sellPipe}
          commodityId={commodityId}
          vendorId={vendorId}
          setCommodityId={setCommodityId}
          setVendorId={setVendorId}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          setAlertMessage={setAlertMessage}
          setAlertStatus={setAlertStatus}
          alertStatus={alertStatus}
        />
      )}
      {openDelModal && (
        <DeletePip
          commodityId={commodityId}
          vendorId={vendorId}
          openDelModal={openDelModal}
          setOpenDelModal={setOpenDelModal}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          setAlertMessage={setAlertMessage}
          setAlertStatus={setAlertStatus}
          alertStatus={alertStatus}
          setOpen={setOpen}
          setIsDeleted={setIsDeleted}
          getadmin={getadmin}
        />
      )}
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GetVendorFeatureList;
