import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { Box, Typography, Button, Snackbar, Alert } from "@mui/material";
import global from "src/Global";
import DigitBox from "./DigitBox";
import { ValidateLoginAuthenticator, Verify2FAFunc } from "src/services/api";
import {
  bglotcon,
  pageHeader,
  rzlt,
  rzltcrc,
  rzltwhite,
} from "src/theme/customStyles";
import { useSelector } from "react-redux";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import dashboardbackground from "../../../assets/Gifs/dashboardbackground";
import dashboardbackground_white from "../../../assets/Gifs/dashboardbackground_white";
import Lottie from "lottie-react";

// components

// ----------------------------------------------------------------------

export default function Verify2FA(props) {
  const [validationCode, setValidationCode] = useState(new Array(6).fill(""));
  const [combinedCode, setCombinedCode] = useState("");
  const location = useLocation();
  const { id } = location.state || {};

  const [loading, setLoading] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.value);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const combined = validationCode.join("");
    setCombinedCode(combined);
  }, [validationCode]);

  async function LogInto() {
    setLoading(true);

    try {
      const response = await Verify2FAFunc(id, combinedCode);
      console.log("that the response:", response);

      if (response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        navigate("/");
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    if (combinedCode.length > 5) {
      LogInto();
    }
  }, [combinedCode]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: !darkMode ? dashboardbackground : dashboardbackground_white,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box sx={bglotcon}>
      <Box sx={[rzlt, { height: "500px" }]}>
        <>
          <Typography
            Typography
            sx={[pageHeader, { textDecoration: "underline" }]}
          >
            Microsoft Authenticator
          </Typography>
        </>

        <Typography
          sx={{
            fontSize: 18,
            marginBottom: "0.5rem",
            textAlign: "center",
            width: "343px",
          }}
        >
          Lütfen Microsoft Authenticator'da oluşan 6 haneli doğrulama kodunu
          girin.
        </Typography>
        <Box sx={{ display: "flex" }}>
          <DigitBox
            validationCode={validationCode}
            setValidationCode={setValidationCode}
          />
        </Box>

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ width: "20rem" }}
          onClick={() => {
            LogInto();
          }}
          disabled={combinedCode.length <= 5}
        >
          Doğrula
        </Button>

        <Snackbar
          open={openAlert}
          onClose={handleAlertClose}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity={alertStatus ? "success" : "error"}>
            {alertmessage}
          </Alert>
        </Snackbar>
        <LoadingOverlay isOpen={loading} />

        <Lottie
          animationData={defaultOptions.animationData}
          loop={defaultOptions.loop}
          autoplay={defaultOptions.autoplay}
          style={{
            zIndex: "-1",
            position: "absolute",
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
}
