import { Helmet } from "react-helmet-async";
// @mui
import { Typography, Box, Snackbar } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
// sections
import { LoginForm } from ".";

import Lottie from "lottie-react";
import GoldtagGateway from "src/assets/Icons/GoldtagGateway";
import dashboardbackground from "../../../assets/Gifs/dashboardbackground";
import dashboardbackground_white from "../../../assets/Gifs/dashboardbackground_white";

import { useSelector } from "react-redux";
import { rzlt, rzltwhite } from "src/theme/customStyles";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive("up", "md", "sm");
  const darkMode = useSelector((state) => state.darkMode.value);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: !darkMode ? dashboardbackground : dashboardbackground_white,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const authCode = localStorage.getItem("authCode");
    console.log(authCode);
    if (authCode && authCode.length > 0 && authCode !== null) {
      localStorage.removeItem("authCode");
    }
  }, []);
  return (
    <Box
      sx={{
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Helmet>
        <title> Giriş Ekranı | Goldtag Dashboard </title>
      </Helmet>

      <Lottie
        animationData={defaultOptions.animationData}
        loop={defaultOptions.loop}
        autoplay={defaultOptions.autoplay}
        style={{
          zIndex: "-1",
          position: "absolute",
          width: "100%",
        }}
      />
      {mdUp && (
        <Box sx={{ marginRight: "338px", zIndex: 2 }}>
          <GoldtagGateway />
        </Box>
      )}

      <Box
        sx={{
          background: darkMode ? "rgb(233,233,233)" : "rgb(26,26,26)",
          background: darkMode
            ? "linear-gradient(90deg, rgba(233,233,233,1) 0%, rgba(233,233,233,0.5) 50%, rgba(233,233,233,0) 100%)"
            : "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(36,36,36,0.5) 50%, rgba(36,36,36,0) 100%)",
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      />

      <Box sx={[darkMode ? rzltwhite : rzlt, { height: "560px" }]}>
        <Box sx={{ alignSelf: "flex-start", marginLeft: "112px" }}>
          <Typography
            style={{
              marginBottom: "0.5rem",
              fontSize: "21px",
              fontWeight: "300",
            }}
          >
            Hoşgeldin,
          </Typography>
          <Typography
            variant="h2"
            style={{ marginBottom: "2rem", fontSize: "36px" }}
          >
            Giriş Yap
          </Typography>
        </Box>

        <LoginForm />
      </Box>
    </Box>
  );
}
